.range-filter {
    &__field {
        &[type="number"] { // sass-lint:disable-line no-vendor-prefixes
            -moz-appearance: textfield; // sass-lint:disable-line no-vendor-prefixes
        }

        &::-webkit-inner-spin-button, // sass-lint:disable-line no-vendor-prefixes
        &::-webkit-outer-spin-button { // sass-lint:disable-line no-vendor-prefixes
            appearance: none;
            margin: 0;
        }
    }
}
