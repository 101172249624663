.recently-viewed {
    margin: auto;

    &.registry-checking ~ .section--product-bottom {
        visibility: hidden;
    }

    &--grid:not(.display-none) ~ .section--product-bottom {
        display: none;
    }

    .product-grid-item {
        &__price {
            .old-price,
            .minimal-price .price-label {
                display: none;
            }
        }
    }
}
