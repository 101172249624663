.quantity-update {
    &--condensed {
        .quantity-update__input {
            width: 56px;
            border-width: $border-width-base;

            @include mq($screen-l) {
                width: 70px;
                border-width: $border-width-base 0;
            }
        }

        .quantity-update__button {
            display: none;

            @include mq($screen-l) {
                display: inline-flex;
            }
        }
    }
}
