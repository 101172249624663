$product-view__title-margin\@large           : $spacer--medium 0;
$product-view__title-margin\@medium          : $spacer--semi-medium 0;
$product-view__title-margin                  : $spacer--large 0;
$product-view__title-font-size\@medium       : $font-size-super-extra-large;
$product-view__sku-color                     : $gray-darkest;
$product-view__grouped-td-vertical-align     : inherit;
$product-view__grouped-td-name-font-weight   : normal;
$product-view__grouped-td-name-margin\@medium: 0;
$product-view__grouped-qty-field-max-width   : min-content;

@import 'product-view';
@import 'rc-product-view';
