.minicart-product {
    &__name-wrapper {
        position: relative;
        padding-bottom: $spacer--medium;
        margin-bottom: $spacer--medium;
    }

    &__sku {
        font-size: $font-size-base;
        color: $gray-dark;

        @include mq($screen-m) {
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translateY(100%);
        }
    }
}
