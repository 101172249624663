@mixin divider-after(
    $horizontal: true,
    $color: $gray-light,
    $size: $border-width-base,
    $width: 100%,
    $spaceBefore: $spacer,
    $spaceAfter: $spacer
) {
    position: relative;

    @if ($horizontal) {
        padding: 0 0 $spaceBefore 0;
        margin: 0 0 $spaceAfter 0;
    }
    @else {
        padding: 0 $spaceBefore 0 0;
        margin: 0 $spaceAfter 0 0;
    }

    &:after {
        content: '';
        position: absolute;
        background: $color;

        @if ($horizontal) {
            height: $size;
            width: $width;
            left: 50%;
            transform: translateX(-50%);
            top: 100%;
        }
        @else {
            width: $size;
            height: $width;
            top: 50%;
            transform: translateY(-50%);
            left: 100%;
        }
    }
}
