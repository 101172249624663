
$product-grid-item__border-width      : $border-width-base,;
$product-grid-item__details-padding   : 0 $spacer;
$product-grid-item__name-line-height  : 1.14;
$product-grid-item__name-height       : 30px;
$product-grid-item__name-height\@small: 30px;
$product-gird-item__primary-margin    : 0;

@import 'product-grid-item';
@import 'rc-product-grid-item';
