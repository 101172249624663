.section {
    &--condensed {
        .section__heading {
            border: $border-base;
            border-width: 0 0 $border-width-base 0;
        }

        .section__content {
            line-height: 2;
            margin: auto;
            max-width: 288px;
            font-size: $font-size-medium;

            @include mq($screen-s) {
                max-width: 648px;
            }

            @include mq($screen-l) {
                max-width: 879px;
            }
        }
    }

    &--product-bottom {
        margin: 0 auto;
    }
}
