.product-view {
    &__title {
        &.heading--page {
            letter-spacing: 1px;

            @include mq($screen-m) {
                font-size: $product-view__title-font-size\@medium;
            }
        }
    }

    &__rating {
        margin: 0 0 $spacer--semi-medium;

        @include mq($screen-m) {
            padding: 0 0 $spacer--medium;
            margin: 0 0 $spacer--large;
        }
    }

    &__short-description-heading {
        color: $color-secondary;
        font-weight: $font-weight-normal;
    }

    &__short-description {
        p {
            line-height: 2;
        }
    }

    &__prices-info {
        font-size: $font-size-base;
        line-height: 1.71;
    }

    &__details-section-heading {
        font-size: $font-size-large;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-bold;

        @include font-padding($font-size-large, 72px)
    }

    &__tabs {
        display: flex;
        flex-direction: column-reverse;
    }
    &__price-details {
        padding: $spacer--semi-medium 0;
        font-weight: $font-weight-medium;
        border: $product-view__form-border;
        border-width: $product-view__form-border-width;
    }

    &__grouped-table {
        border-width: 0;

        td {
            display: table-cell;

            &:before {
                display: none;
            }
        }

        th {
            display: table-cell;

            &.product-view__grouped-th--qty {
                @include mq($screen-xs) {
                    text-align: right;
                }
            }
        }

        tr {
            padding: 0;
        }

        thead {
            tr {
                display: flex;
                justify-content: space-between;
                border-bottom: $border-base;

                @include mq($screen-m) {
                    display: table-row;
                }
            }
        }

        .price {
            .price__value {
                @include mq($screen-xs) {
                    font-size: $font-size-base;
                }
            }
        }
    }

    &__grouped-product-name-cell {
        width: 100%;
        padding: 0 $spacer--medium 0 0;
    }

    &__grouped-product-name {
        min-width: 150px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: $font-size-large;
    }

    &__grouped-cell-content {
        padding: 0 $spacer--medium 0 0;
        font-size: $font-size-base;
        height: 50%;

        &--main {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &--additional {
            font-weight: $font-weight-base;
            color: $color-secondary;
            text-transform: lowercase;
        }
    }

    &__grouped-attribute {
        margin: 0 $spacer 0 0;
    }

    &__grouped-qty {
        margin: 0;
    }

    &__details,
    &__description {
        margin: 0 $spacer;
    }

    &__short-description {
        margin-bottom: $spacer--semi-medium;
        line-height: 2;
    }

    &__short-description-heading {
        font-weight: $font-weight-base;
    }

    &__price-details {
        padding: $spacer--semi-medium 0;
        font-weight: $font-weight-medium;
        border: $product-view__form-border;
        border-width: $product-view__form-border-width;
    }

    &__sku {
        display: block;
    }
}
