.dashboard-form {
    .fieldset.info {
        .input {
            max-width: calc(50% - 8px);
        }
    }
    .form-additional__info {
        max-width: calc(50% - 8px);
        margin-bottom: $spacer--semi-medium;
    }
    .customer-extend {
        .actions-group {
            margin: 28px 0 16px;
        }
    }
}
