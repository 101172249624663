
.amgdprcookie-groups-modal {
    .modal-inner-wrap {
        border-radius: 4px;
        box-shadow: 0 -2px 18px rgba(0, 0, 0, .06);
        max-width: 740px;
    }

    &.-table .modal-inner-wrap {
        max-width: 1290px;
        min-width: 700px;
        overflow-x: auto;
    }

    .modal-title {
        border-bottom: none;
        font-size: 20px;
        font-weight: bold;
    }

    .action-close:focus {
        box-shadow: $amgdprcookie-button__focus;
    }

    .amgdprcookie-form {
        padding: 0 10px;
    }

    .amgdprcookie-done {
        background-color: $amgdprcookie-link__color;
        border-radius: 3px;
        box-shadow: none;
        color: $white;
        font-weight: bold;
        letter-spacing: 1.35px;
        text-transform: uppercase;
        transition: background-color .3s ease-out;
        width: 100%;
    }

    .amgdprcookie-done:hover {
        background-color: $amgdprcookie-btn-done__highlight;
    }

    .amgdprcookie-done:focus {
        background-color: $amgdprcookie-btn-done__highlight;
        box-shadow: $amgdprcookie-button__focus;
    }

    &:not(.-table) .modal-footer {
        padding-top: 0;
    }
}

.amgdprcookie-groups-modal .amgdprcookie-form-container,
.amgdprcookie-groups-modal .amgdprcookie-tbody {
    display: block;
    max-height: 500px;
    overflow-y: auto;
}

.amgdprcookie-bar-container {
    & {
        background: $white;
        box-shadow: 0 -2px 18px rgba(0, 0, 0, .06);
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 30px 10px;
        z-index: 10;
    }

    &.modal-popup {
        bottom: unset;
        left: unset;
        overflow-y: unset;
        pointer-events: auto;
        position: unset;
        right: unset;
        top: unset;
        transition: unset;
    }

    .amgdprcookie-buttons-block {
        display: flex;
        flex-direction: column;
    }

    .amgdprcookie-policy {
        margin-bottom: 20px;
        max-height: 30vh;
        max-width: 640px;
        overflow-y: auto;
    }

    .amgdprcookie-button:first-child {
        margin-bottom: 10px;
    }

    .amgdprcookie-buttons-block .amgdprcookie-button {
        margin-bottom: 0;
        padding: 10px 30px;
    }

    .action-close {
        & {
            line-height: 14px;
            right: 10px;
            top: 10px;
            cursor: pointer;
        }

        > span {
            display: none;
        }

        &:focus,
        &:active {
            box-shadow: $amgdprcookie-button__focus;
            opacity: .9;
        }

        &:before, &:after {
            position: absolute;
            top: 20px;
            content: ' ';
            height: 24px;
            width: 2px;
            background-color: $black;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}

.amgdprcookie-buttons-block {
    & {
        display: flex;
        flex-direction: column;
    }

    &.-settings {
        padding-bottom: 30px;
    }

    .amgdprcookie-button {
        & {
            background: $white;
            border: none;
            border-radius: 3px;
            box-shadow: none;
            color: $amgdprcookie-link__color;
            font-weight: bold;
            letter-spacing: 1.35px;
            margin-top: 15px;
            padding: 10px 15px;
            text-transform: uppercase;
            transition: opacity .3s ease, color .3s ease-out, background-color .3s ease-out, border .3s ease-out;
        }

        &:hover {
            opacity: .9;
        }

        &:active,
        &:focus {
            box-shadow: $amgdprcookie-button__focus;
            opacity: .9;
        }

        &.-allow,
        &.-save {
            background: $amgdprcookie-link__color;
            color: $white;
            flex-basis: 33%;
        }
    }
}

.amgdprcookie-modal-template {
    & {
        background: $white;
        margin-left: 5px;
        overflow-y: auto;
    }

    .action-close {
        & {
            background: $white;
            line-height: 14px;
            margin: 0;
            padding: 8px;
            right: 0;
            top: 0;
            z-index: 1;
        }

        &:focus,
        &:active {
            background: $white;
            box-shadow: $amgdprcookie-button__focus;
            opacity: .9;
        }

        &:hover {
            background: $white;
        }
    }

    .amgdprcookie-button.-allow:not(.-save) {
        background: inherit;
        color: $amgdprcookie-link__color;
        margin-bottom: 0;
    }
}

.amgdprcookie-modal-container .modal-content {
    padding: 0;
}

.amgdprcookie-groups-container {
    box-shadow: 0 -2px 18px rgba(0, 0, 0, .06);
    direction: rtl;
    height: auto;
    overflow-y: scroll;
}

.amgdprcookie-policy-container {
    bottom: 0;
    box-shadow: 0 -2px 18px rgba(0, 0, 0, .06);
    padding: 24px;
    position: absolute;
}

.amgdprcookie-text-container .amgdprcookie-policy {
    display: inline;
}

.amgdprcookie-modal-container.modal-popup.modal-slide {
    left: 0;
    pointer-events: none;
}

.amgdprcookie-modal-container .modal-inner-wrap {
    & {
        height: 100%;
        margin: 0;
        position: relative;
    }

    .modal-header {
        position: absolute;
        right: 0;
    }

    .action-close {
        & {
            margin: 5px;
            padding: 0;
        }

        &:before {
            background: $amgdprcookie-btn-close-icon__background-image no-repeat center;
            content: '';
            height: 25px;
            width: 25px;
        }

        &:focus {
            box-shadow: $amgdprcookie-button__focus;
        }
    }
}

.amgdprcookie-settings-form .amgdprcookie-toggle-cookie .amgdprcookie-input.disabled + .amgdprcookie-label {
    background: $amgdprcookie-toogle-disable__background;
}

.amgdprcookie-groups-modal {
    .amgdprcookie-table .amgdprcookie-row,
    .amgdprcookie-table thead tr,
    .amgdprcookie-table thead {
        display: table;
        table-layout: fixed;
        width: 100%;
    }

    .amgdprcookie-cell.-name {
        word-wrap: break-word;
    }
}

.amgdprcookie-modal-container .amgdprcookie-modal-template {
    height: 100%;
    margin-left: 0;
    min-width: 400px;
    pointer-events: auto;
    position: relative;
    width: 20%;
    z-index: 900;
}

.ammodals-overlay {
    background-color: rgba(51, 51, 51, .55);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 80;
}


//
//  Mobile
//  ----------------------------------------------
@media all and (max-width: 768px) {
    .amgdprcookie-groups-modal {
        &.-table .modal-inner-wrap {
            min-width: 95%;
        }

        .amgdprcookie-table {
            font-size: 1rem;
        }
    }

    .modal-popup .modal-content {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .amgdprcookie-bar-container {
        .action-close {
            padding: 10px;
            right: 0;
            top: 0;
        }
    }

    .amgdprcookie-modal-container .amgdprcookie-modal-template {
        min-width: unset;
        width: unset;
    }
}

//
//  Tablet
//  ----------------------------------------------
@media all and (min-width: 768px) {
    .amgdprcookie-groups-modal {
        .amgdprcookie-cookie-container,
        .amgdprcookie-header,
        .amgdprcookie-text {
            padding: 0;
        }
    }

    .amgdprcookie-modal-container .modal-inner-wrap {
        background: $white;
        min-width: 400px;
        right: unset;
        width: 21%;
    }

    .amgdprcookie-table .amgdprcookie-cell.desc,
    .amgdprcookie-table .amgdprcookie-title.-wide {
        width: 30%;
    }

    .amgdprcookie-table .amgdprcookie-cell,
    .amgdprcookie-table .amgdprcookie-title {
        width: 15%;
    }

    .amgdprcookie-bar-container .amgdprcookie-buttons-block {
        & {
            flex-direction: row;
            margin-bottom: 5px;
        }

        .amgdprcookie-button {
            margin-bottom: 0;
        }
    }
}

//
//  Desktop
//  ----------------------------------------------
@media all and (min-width: 1024px) {
    .amgdprcookie-groups-modal .amgdprcookie-form {
        padding: 0 20px;
    }

    .checkout-index-index {
        .modal-popup.amgdprcookie-modal-container {
            .modal-inner-wrap {
                left: unset;
                margin-left: unset;
                width: 21%;
            }
        }
    }
}
