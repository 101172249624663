.product-grid-item {
    &__link {
        @include divider-after($width: 90%, $spaceBefore: 0);
    }

    &__name {
        font-size: $font-size-base;

        @include mq($screen-m) {
            min-height: 50px;
        }

        @include mq($screen-m) {
            font-size: $font-size-medium;
        }
    }

    &__name-link {
        // sass-lint:disable-block no-vendor-prefixes
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @include mq($screen-m) {
            line-height: $font-line-height;
        }
    }

    &__sku {
        color: $gray-dark;
        font-size: $font-size-small;

        @include mq($screen-m) {
            font-size: $font-size-base;
        }
    }

    &__color-variants {
        height: 16px;
    }

    &__color-variant {
        width: 32px;
        height: 16px;
        border: $border-width-base $border-style-base $black;
        display: inline-block;
    }

    &__color-variants-info {
        width: 100%;
        color: $gray-dark;
        min-height: 22px;
    }

    &__actions {
        padding: 0;

        @include mq($screen-m) {
            position: absolute;
            transform: translateY(100%);
            display: block;
            box-sizing: content-box;
            bottom: 0;
            left: -1px;
            padding: 0 0 $spacer--medium;
            background-color: white;
            border-style: $border-style-base;
            border-color: $gray-light;
            border-width: 0 $border-width-base $border-width-base;
            z-index: $z-index-normal;
        }
    }

    &__primary-action {
        padding: 0;
        width: 100%;
        text-align: center;

        @include mq($screen-m) {
            padding: 0 $spacer--medium;
        }
    }

    &__details {
        @include mq($screen-m) {
            padding: 0 $spacer--medium;
        }
    }

    &__price {
        @include mq($screen-s) {
            width: 100%;
        }

        .price__value {
            font-size: $font-size-base;

            @include mq($screen-m) {
                font-size: $font-size-large;
            }

            &.price__value--old {
                display: none;
            }
        }

        .price-box > p {
            margin: 0 0 $spacer 0;
        }
    }

    &:not(:hover) {
        .product-grid-item__actions {
            @include mq($screen-m) {
                @include visually-hidden();
            }
        }
    }

    &__link {
        &--hover-image {
            &:hover {
                .product-grid-item__image-wrapper {
                    opacity: 0;

                    &--hover {
                        opacity: 1;
                    }
                }
            }

            .product-grid-item__image-wrapper {
                opacity: 1;
                transition: $transition-base;

                &--hover {
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}

.grid-items {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    @include mq($screen-m) {
        flex-wrap: nowrap;
    }
    .slider__item {
        max-width: 48%;
        display: flex !important;
        @include mq($screen-m) {
            width: 25%;
            max-width: 25%;
        }
    }
}