$table__tbody-grid-gap: 100px;
$table-border         : $border-width-base $border-style-base $gray;

.table {
    &--space-between {
        margin: 0;
        border: 0;
        border-radius: 0;
        font-size: $font-size-base;

        @include mq($screen-s) {
            font-size: $font-size-medium;
        }

        thead {
            tr {
                padding: 0;
                border-bottom: 0;
            }
        }

        tbody {
            display: grid;
            grid-template-columns: 1fr;

            @include mq($screen-l) {
                grid-template-columns: repeat(2, 1fr);
                column-gap: $table__tbody-grid-gap;
            }
        }

        th {
            border-bottom: 0;
            border-right: 0;
            text-align: left;
            font-weight: $font-weight-bold;

            &[scope="row"] {
                padding: $spacer--small 0;

                & + td {
                    padding: 0;
                    flex-flow: row nowrap;
                    text-align: right;

                    &:before {
                        text-align: left;
                    }

                    @include mq($screen-m) {
                        padding: $spacer--small 0;
                    }
                }
            }
        }

        td {
            width: 100%;
            border-bottom: 0;
            border-right: 0;
            justify-content: space-between;

            @include mq($screen-m) {
                width: initial;
            }

            &:before {
                max-width: 100%;
            }
        }

        tr {
            display: flex;
            justify-content: space-between;
            border: none;
        }
    }

    &--hidden-border {
        $table__border: $table-border;

        border-collapse: collapse;
        border-style: hidden;

        thead {
            th {
                position: relative;
                border-right: none;
                border-bottom: $table-border;

                &:not(:last-child)::after {
                    content: "";
                    height: 75%;
                    width: 1px;
                    right: -1px;
                    top: 50%;
                    transform: translateY(-50%);
                    background: $gray-light;
                    position: absolute;
                    z-index: $z-index-normal;
                }
            }
        }

        tfoot {
            td {
                border: none;

                @include mq($screen-m) {
                    padding: $spacer--large $spacer--medium;
                }
            }
        }

        .table__td {
            &--no-border-left {
                border-left: none;
            }

            &--no-border-right {
                border-right: none;
            }
        }
    }
}
