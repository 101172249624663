.heading {
    &--first-level {
        font-family: $font-family-secondary;
    }

    &--second-level,
    &--third-level {
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }
}
