//
//  Promo Base
//  ____________________________________________

//
//  Variables
//  --------------------------------------------

$ampromo-items__background-color: #fff;
$ampromo-overlay__background-color: rgba(0, 0, 0, .8);
$ampromo-item__border: 1px solid #ededed;
$ampromo-overlay-close__background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMWwxMCAxMG0wLTEwTDEgMTEiIHN0cm9rZT0iI0IyQjJCMiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPg==);

//
//  Common
//  ----------------------------------------------


.ampromo-overlay {
    & {
        position: fixed;
        top: -9999px;
        left: 0;
        z-index: 10000;
        width: 100%;
        height: 100%;
        background-color: $ampromo-overlay__background-color;
        text-align: center;
        opacity: 0;
        transition: opacity 0.4s linear;
    }

    &.-show {
        top: 0;
        opacity: 1;
    }
}

.ampromo-popup-wrapper {
    & {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        max-height: 95vh;
        overflow: auto;
        margin: 30px auto;
        padding: 24px;
        border: $ampromo-item__border;
        text-align: left;
        background-color: $ampromo-items__background-color;
    }

    .ampromo-popup-title,
    .ampromo-item-buttons,
    .ampromo-title {
        text-align: center;
    }

    .ampromo-popup-title .ampromo-counter {
        font-weight: bold;
    }

    .ampromo-item {
        & {
            position: relative;
            box-sizing: border-box;
            padding: 20px;
            margin: 0 8px 7px;
            border: $ampromo-item__border;
            transition: .6s;
        }

        &:hover {
            background: #ccc;
        }
    }

    .ampromo-item .fieldset .field {
        margin-bottom: 10px;
    }

    .ampromo-item .fieldset .field .label {
        margin-bottom: 3px;
    }

    .ampromo-popup-title {
        margin: 0 auto 20px;
    }

    .ampromo-close {
        & {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 1;
            display: block;
            width: 16px;
            height: 16px;
            margin: 0;
            padding: 0;
            background: $ampromo-overlay-close__background-image no-repeat;
            background-size: contain;
            font-size: 0;
            cursor: pointer;
            opacity: .8;
        }

        &:hover {
            opacity: 1;
            transition: opacity .2s ease;
        }
    }

    .ampromo-button {
        box-shadow: none;
        border: none;
    }

    .ampromo-item.-selected {
        background: #1f78c2;
    }

    .ampromo-item.-selected .ampromo-options {
        max-height: 690px;
    }

    .ampromo-item-image {
        display: block;
        margin: 7px auto;
    }

    .ampromo-title {
        margin: 0;
    }

    .slick-dots {
        position: initial;
    }

    .slick-dotted.slick-slider {
        margin-bottom: 2rem;
    }
}


@media only screen and (max-width: 1000px) and (min-width: 701px) {
    .ampromo-overlay .ampromo-gallery {
        width: 570px;
    }

    .ampromo-popup-wrapper {
        padding: 24px 54px;
    }
}

@media only screen and (max-width: 700px) {
    .ampromo-overlay .ampromo-gallery {
        width: 280px;
        margin: 0 auto;
    }

    .ampromo-popup-wrapper {
        padding: 24px 54px;
    }
}

@media only screen and (max-width: 400px) {
    .ampromo-overlay .ampromo-gallery {
        margin: 0 auto 30px auto;
    }

    // rewrite slick styles
    .ampromo-overlay .slick-prev,
    .ampromo-overlay .slick-next {
        display: none !important;
    }

    .ampromo-popup-wrapper {
        padding: 15px;
    }
}

@media only screen and (min-width: 1024px) {
    .ampromo-popup-wrapper {
        & {
            max-width: 960px;
            width: 100%;
        }

        .slick-dotted.slick-slider {
            margin-bottom: 3rem;
        }
    }
}
