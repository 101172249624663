$loader__size              : 100%;
$loader__z-index           : $z-index-high;
$loader__background        : rgba(251, 251, 251, 0.5);
$loader__icon-size         : 32px;
$loader__icon-border       : 2px solid $gray-light;
$loader__icon-border-top   : 2px solid $color-primary;
$loader__icon-border-radius: 50%;
$loader__icon-animation    : rotate 1s infinite linear;

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: $loader__size;
    height: $loader__size;
    background-color: $loader__background;
    z-index: $loader__z-index;

    &--visible {
        display: block;
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: $loader__icon-size;
        height: $loader__icon-size;
        margin: auto;
        border: $loader__icon-border;
        border-top: $loader__icon-border-top;
        border-radius: $loader__icon-border-radius;
        animation: $loader__icon-animation;
    }
}
