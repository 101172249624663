.message {
    font-size: $font-size-medium;

    @include mq($screen-l) {
        font-size: $font-size-large;
    }

    &--reminder {
        background: $gray-light;
        border: none;
        padding: $spacer--semi-medium $spacer--medium;
        font-weight: $font-weight-medium;
        font-size: $font-size-base;
    }
}
