.add-to-cart {
    &__button-login {
        white-space: nowrap;
    }

    &--sticky-guest {
        min-height: 56px;

        .add-to-cart__title {
            width: auto;
            max-width: 70%;

            @include mq($screen-m) {
                width: $add-to-cart__title-width\@medium;
            }

            @include mq($screen-l) {
                width: $add-to-cart__title-width\@large;
            }
        }

        .add-to-cart__action {
            flex-flow: row nowrap;
        }

        .add-to-cart__action-secondary {
            bottom: 48px;

            @include mq($screen-m) {
                bottom: initial;
            }
        }
    }

    &__button-extend-text {
        display: none;

        @include mq($screen-m) {
            display: block;
            margin-left: 4px;
        }
    }
}
