.slider {
    &--products {
        .slick-list {
            @include mq($screen-m) {
                overflow: visible;
                overflow-x: hidden;

                padding-bottom: 72px;
                margin-bottom: -72px;
            }
        }
    }
}
